<template>
  <div class="roleHeader flex gap-4.5 w-full text-base-content justify-end">
    <div class="roleHeader__item font-semibold text-sm w-24 text-center" :class={allRole:changeUi}>Block Visibility</div>
      <div class="flex gap-2 w-min text-base-content justify-end">
        <HeaderBlock
          v-for="(role, id) in roles"
          :key="id"
          class="roleHeader__item"
          :title="role"
          :changeUi="changeUi"
        />
      </div>
    <div class="roleHeader__item font-semibold text-sm w-24">Use In Case Creation</div>
    <div class="roleHeader__item font-semibold text-sm w-24">Report Visibility</div>
  </div>
</template>

<script>
import HeaderBlock from "./components/header-block.vue";

export default {
  name: 'field-roles-header',
  components: {
    HeaderBlock,
  },
  data() {
    return {
      roles: [
        'Analyst',
        'Subject',
        'Client',
        'Referee',
      ],
      inCheckRole:null
    }
  },
  props:{
    changeUi:{
      type:Boolean,
    },
  },
}
</script>

<style lang="scss" scoped>
.item-2 {
  margin-right: 6px;
}
.item-1{
  margin-right: 7px;
}
.item-0{
  margin-right: 6px;
}
.allRole{
    text-align:center;
}
</style>